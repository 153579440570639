<template>
  <div id="preview_footer" style="position: relative;" :style="`height:${landing.branding.footer_preview&&!showFooter?landing.branding.footer_size.height+'px':'auto'};`" v-if="showFooterPreview">
    <div class="canvas-footer" style="display:flex;flex-direction:column;margin:0 auto;padding:15px;"
         :style="`position:relative;z-index:2;overflow:hidden;border-top-left-radius:${landing.branding.footer_size.radius}px;
         border-top-right-radius:${landing.branding.footer_size.radius}px;width:${landing.branding.footer_size.width}%;
         min-height:${landing.branding.footer_size.height}px;background:${landing.branding.footer_preview&&!landing.branding.footer_img?'transparent':'url('+landing.branding.footer_img+') center/cover no-repeat'};`">
      <div  style="height:auto;flex:1 1 auto;display:flex;align-items:flex-end;" v-if="showFooter">
        <div v-html="htmlData(footer_text[landing.current_lang])">

        </div>
      </div>
        <div style="height:auto;flex:1 1 auto;display:flex;justify-content:center;align-items:flex-end;">
          <div
          class="canvas__banner"
        >
          <div
            style="
              font-family: Lato,serif;
              font-size: 13px;
              text-align: center;
              color: #999;
            "
          >
            The service is powered by
            <a target="_blank" :href="VUE_APP_LINK">Tourist inc</a>
            technology <br />
            <div v-if="landing.free">
              This Free version is available for any hotel or campsite,<br />
              would you like a similar menu? Go to
              <a target="_blank" :href="VUE_APP_LINK">guesteco.com</a>
            </div>
          </div>
        </div>
        </div>
    </div>
    <div class="canvas-footer_preview" style="margin:0 auto;padding:15px;" :style="`left:${(343-(343*(+landing.branding.footer_size.width/100)))/2}px;position:absolute;top:0;margin-bottom:${landing.branding.bottomMenu.show?'60px':'0'};overflow:hidden;border-top-left-radius:${landing.branding.footer_size.radius}px;border-top-right-radius:${landing.branding.footer_size.radius}px;width:${landing.branding.footer_size.width}%;height:${landing.branding.footer_size.height}px;`" v-if="landing.branding.footer_preview">
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name:"FooterPreview",
    props:['page'],
    computed:{
        ...mapState(['landing']),
        ...mapState('branding',['footer_text']),
      showFooterPreview(){
            if(this.page === 'lining'){
                return false;
            }
            if(this.page === 'intro'){
                return false;
            }
            if(this.page === 'topMenu'){
                return false;
            }
            if(this.page === 'sidebar'){
                return false;
            }
            if(this.page === 'reviews' && this.landing.free){
              return false
            }
            if(this.page === 'advanced' && this.landing.advanced.tab === 9){
              return false
            }
            if(this.page === 'advanced' && this.landing.advanced.tab === 12){
              return false
            }
            if(this.page === 'advanced' && this.landing.advanced.tab === 3) {
              return false
            }
          return true;
      },
      VUE_APP_LINK(){
          return process.env.VUE_APP_LINK
      },
      showFooter(){
          if(this.footer_text){
            return !!this.footer_text[this.landing.current_lang];
          }else{
            return false
          }
      }
    },
    methods:{
      htmlData(data, type) {
      if(data){
      const weatherInd = data.split(" ").indexOf(":weather:");
      let result = data.split(" ");
      if (weatherInd && this.landing.advanced.global.show_weather) {
        const newArr = result;
        newArr[weatherInd] =
          this.landing.advanced.global.weather.week.daily[0].temp.day.toFixed(
            0
          ) + " ℃";
        result = newArr;
      }
      const timeInd = data.split(" ").indexOf(":time:");
      if (timeInd) {
        const newArr = result;
        newArr[timeInd] = this.datenow;
        result = newArr;
      }
      const dateInd = data.split(" ").indexOf(":date:");
      if (dateInd) {
        const newArr = result;
        newArr[dateInd] = this.dateToday;
        result = newArr;
      }
      const user_name = data.split(" ").indexOf(":name:");
      if (
        user_name &&
        this.landing.advanced.global.auth &&
        this.landing.advanced.auth.name &&
        type === "title"
      ) {
        const newArr = result;
        newArr[user_name] = "Agent";
        result = newArr;
      }
      const user_surname = data.split(" ").indexOf(":surname:");
      if (
        user_surname &&
        this.landing.advanced.global.auth &&
        this.landing.advanced.auth.name &&
        type === "title"
      ) {
        const newArr = result;
        newArr[user_surname] = "Smith";
        result = newArr;
      }
      const user_birthday = data.split(" ").indexOf(":birthday:");
      if (
        user_surname &&
        this.landing.advanced.global.auth &&
        this.landing.advanced.auth.name &&
        type === "title"
      ) {
        const newArr = result;
        newArr[user_birthday] = "24.03.1999";
        result = newArr;
      }
      const user_room = data.split(" ").indexOf(":room:");
      if (
        user_surname &&
        this.landing.advanced.global.auth &&
        this.landing.advanced.auth.name &&
        type === "title"
      ) {
        const newArr = result;
        newArr[user_room] = "707";
        result = newArr;
      }

      const user_auth = data.split(" ").indexOf(":auth:");
      if (user_auth && this.landing.advanced.global.auth && !type) {
        const newArr = result;
        newArr[user_auth] = `<a href="#">Login</a>`;
        result = newArr;
      }
      const user_logout = data.split(" ").indexOf(":logout:");
      if (user_logout && this.landing.advanced.global.auth && !type) {
        const newArr = result;
        newArr[user_logout] = `<a href="#">Logout</a>`;
        result = newArr;
      }
      return result.join(" ");
      }
    },
    }
}
</script>

<style>
</style>
